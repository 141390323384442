<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="1"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-row :gutter="24" class="page_bar">
					<el-col :span="5" class="page_bar_title">资源中心</el-col>
					<el-col :span="19" class="page_bar_tool">
						<el-button type="primary" @click="showUploadFileDialog" icon="el-icon-plus" size="small" >上传资源</el-button>
						<el-button type="primary" @click="refreshList" icon="el-icon-refresh-right" size="small" plain >刷新列表</el-button>
						<el-input placeholder="输入标题查找课件" v-model="searchValue" class="input-with-select" size="small" >
							<el-button slot="append"  icon="el-icon-search" @click="handleSearch"></el-button>
						</el-input>
						<el-button  icon="el-icon-arrow-down" size="small" @click="handleShowSetSearch" plain></el-button>
					</el-col>
				</el-row>

				<el-row class="more_search_bar" v-if="show_more_search">
					<el-row>
						文件类型：
						<el-radio-group v-model="select_search_type" size="mini" class="select_type">
							<el-radio label="all" border>全部</el-radio>
							<el-radio label="pic" border>图片</el-radio>
							<el-radio label="video" border>视频</el-radio>
							<el-radio label="audio" border>音频</el-radio>
							<el-radio label="doc" border>文档</el-radio>
						</el-radio-group>
					</el-row>
					<!-- <el-row class="sux_io_row">
						搜索范围：
						<el-radio-group v-model="select_search_word" size="mini" class="select_type">
							<el-radio label="1" border>课件名称</el-radio>
							<el-radio label="2" border>课件资源标题</el-radio>
						</el-radio-group>
					</el-row>
					<el-row class="sux_io_row">
						搜索关键词：
						<el-input placeholder="输入标题查找课件" v-model="searchValue" class="input-with-select" size="mini" ></el-input>
					</el-row> -->
					<el-row class="sux_io_row">
						<el-button class="button_big_reset" size="small" type="primary"  @click="handleSetTypeSearch">确定</el-button>
						<el-button class="button_big_search" size="small" type="primary" plain  @click="handleResetType">重置</el-button>
					</el-row>
					<el-button @click="handleCloseSetSearch" class="close_search_tool" type="danger" icon="el-icon-close" circle></el-button>
				</el-row>

				<el-table v-loading="loading" :data="tableData" size="medium" stripe highlight-current-row>	
					<!-- <el-table-column  label="" width="54" >
						<template>
							<img src="@/assets/icon_sxo.png" class="icon_file_row_type">
						</template>
					</el-table-column> -->
					<el-table-column label="课件资源名称" width="400">
						<template slot-scope="scope">
							<div class="scope_case_title">{{scope.row.case_name}}</div>
							<div class="scope_case_showid">资源ID:{{scope.row.uuid}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="onshow_name" label="展示到小程序" ></el-table-column>
					<el-table-column prop="ontop_name" label="推荐到首页"  ></el-table-column>
					<el-table-column prop="classname" label="大类" width="80"></el-table-column>
					<el-table-column prop="second_classname" label="二类" width="80"></el-table-column>
					<el-table-column prop="third_classname" label="三类"  width="80"></el-table-column>
					<el-table-column prop="user_name" label="上传人" ></el-table-column>
					<el-table-column prop="kindergarten_name" label="所属园所" ></el-table-column>
					<!-- <el-table-column prop="add_date" label="创建日期" width="180"></el-table-column> -->
					<el-table-column label="操作" width="340">
						<template slot-scope="scope">
							<el-button type="warning" size="mini" @click="handleSetShow(scope.row.uuid)" plain>推荐设置</el-button> 
							<el-button type="primary" size="mini" @click="handleShowUploadFile(scope.row.uuid)" plain>查看</el-button>
							<el-button type="danger" size="mini" v-if="login_user_power=='admin_user'"  @click="handleDelCase(scope.row.uuid)"  plain>删除</el-button>
							<el-button type="danger" size="mini" v-if="login_user_power=='k_admin'"  @click="handleDelCase(scope.row.uuid)"  plain>删除</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-pagination
				background
				layout="prev, pager, next"
				:page-size="15" 
				:current-page="pagenow"
				:total="totalnum" style="margin:20px 0 80px 0" @current-change="handleCurrentChange" >
				</el-pagination>
					
				</el-main>

			<el-footer> copyright &copy; xjjgweiguang.com</el-footer>
		</el-container>
	</el-container>


	<el-dialog title="设置资源推荐" :close-on-click-modal="false" :visible.sync="dialogShowInMiniVisible" width="760px">
		
		<el-row :gutter="24"  class="xdif_row">
			<el-col :span="9" >
				<strong class="row_label">在小程序展示：</strong>
				<el-switch
					v-model="set_onshow_mini"
					active-color="#13ce66"
					inactive-color="#ff4949" 
					active-value="1" 
					inactive-value="0" >
				</el-switch>
			</el-col>
		</el-row>
		

		<el-row :gutter="24"  class="xdif_row">
			<el-col :span="9">
				<strong class="row_label">推荐到小程序首页：</strong>
				<el-switch
					v-model="set_ontop_mini"
					active-color="#13ce66"
					inactive-color="#ff4949" 
					active-value="1" 
					inactive-value="0" >
				</el-switch>
			</el-col>
		</el-row>


		<el-row :gutter="24" class="xdif_row">
			<el-col :span="8">
				<strong class="row_label">每份销售价（积分）：</strong>
				<el-input type="number" max="500" min="0" v-model="set_case_price" value="0" placeholder="0为免费" ></el-input>
			</el-col>
		</el-row>

		<el-row :gutter="24" class="sixc_row">
			<el-col :span="9" ><strong class="row_label">资源展示封面：</strong></el-col>
		</el-row>

		<el-row :gutter="24" class="sixc_row">
			<el-col :span="24">
				<div class="has_pic_list" v-if="hasPicList.length != 0">
					<div class="pic_list_li" v-for="item in hasPicList" :key="item.id" >
						<el-image fit="cover" class="pic_images" :src="'https://www.xjjgweiguang.com/uploads/' + item.pic_url"></el-image>
						<el-button class="btn_del_picimg" @click="handleDelHasPic(item.id)" type="danger" icon="el-icon-delete" circle></el-button>
					</div>
					<div class="clear"></div>
				</div>
				<el-upload
				action="https://www.xjjgweiguang.com/kindergarten/index/postsImages"
				ref="picupload"
				list-type="picture-card" 
				:auto-upload="false"  
				:on-change="handlepictureChange" 
				:on-success="handlePicUploadSuccess" 
				accept="image/*" :file-list="picList">
					<i slot="default" class="el-icon-plus"></i>
					<div slot="file" slot-scope="{file}">
					<img
						class="el-upload-list__item-thumbnail"
						:src="file.url" alt=""
					>
					<span class="el-upload-list__item-actions">
						<span
						v-if="!disabled"
						class="el-upload-list__item-delete"
						@click="handleRemovePic(file)"
						>
						<i class="el-icon-delete"></i>
						</span>
					</span>
					</div>
				</el-upload>
				
			</el-col>
		</el-row>

		<div class="sxia_tips">
			此功能用来设置资源是否在微信小程序中展示，要展示的资源需补充封面图片，最多4张。<br>
			（只能上传<strong>jpg/png</strong>图片文件，单图不超<strong>800kb</strong>，推荐尺寸：<strong>宽1080px  高836px</strong>）
		</div>


		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogShowInMiniVisible = false">取 消</el-button>
			<el-button type="primary" @click="handleSaveShowMini">保存</el-button>
		</span>
	</el-dialog>



	<el-dialog :title="rowDetail.case_name" :visible.sync="dialogTableVisible" width="60%">
		<el-descriptions class="margin-top" :column="1" size="mini" border>
			<el-descriptions-item>
			<template slot="label">
				资源ID
			</template>
			{{rowDetail.uuid}}
			</el-descriptions-item>
			<el-descriptions-item>
			<template slot="label">
				资源说明
			</template>
			{{rowDetail.case_body}}
			</el-descriptions-item>
			<el-descriptions-item>
			<template slot="label">
				上传人
			</template>
			{{rowDetail.add_date}}
			</el-descriptions-item>

		</el-descriptions>

		<el-table :data="rowDetail.filelist" border stripe size="mini" style="margin-top:10px">
			<el-table-column  width="40">
				<template slot-scope="scope">
					<img v-if="scope.row.filetype == 'jpeg' || scope.row.filetype == 'jpg'  || scope.row.filetype == 'gif' || scope.row.filetype == 'png'" src="@/assets/icon_pic.png" class="icon_file_type">
					<img v-if="scope.row.filetype == 'mp4' || scope.row.filetype == 'mkv'  || scope.row.filetype == 'mov'" src="@/assets/icon_video.png" class="icon_file_type">
					<img v-if="scope.row.filetype == 'mp3'" src="@/assets/icon_audio.png" class="icon_file_type">
					<img v-if="scope.row.filetype == 'doc' || scope.row.filetype == 'docx'" src="@/assets/icon_word.png" class="icon_file_type">
					<img v-if="scope.row.filetype == 'xls' || scope.row.filetype == 'xlsx'" src="@/assets/icon_excel.png" class="icon_file_type">
					<img v-if="scope.row.filetype == 'ppt' || scope.row.filetype == 'pptx'" src="@/assets/icon_ppt.png" class="icon_file_type">
					<img v-if="scope.row.filetype == 'pdf'" src="@/assets/icon_pdf.png" class="icon_file_type">

					<!-- <img v-if="scope.row.stype == '2'" src="@/assets/icon_audio.png" class="icon_file_type">
					<img v-if="scope.row.stype == '3'" src="@/assets/icon_excel.png" class="icon_file_type">
					<img v-if="scope.row.stype == '4'" src="@/assets/icon_pdf.png" class="icon_file_type">
					<img v-if="scope.row.stype == '5'" src="@/assets/icon_pic.png" class="icon_file_type">
					<img v-if="scope.row.stype == '6'" src="@/assets/icon_ppt.png" class="icon_file_type">
					<img v-if="scope.row.stype == '7'" src="@/assets/icon_txt.png" class="icon_file_type">
					<img v-if="scope.row.stype == '8'" src="@/assets/icon_word.png" class="icon_file_type"> -->
				</template>
			</el-table-column>
			<el-table-column
				prop="filename"
				label="包含文件" >
			</el-table-column>
			<el-table-column label="操作" width="170">
				<template slot-scope="scope">
					<el-button type="success" size="mini" @click="handleShowCloud(scope.row.filename,1)">查看</el-button>
					<el-button type="primary" size="mini" @click="handleShowCloud(scope.row.filename,2)">下载</el-button>
					<!-- <el-button @click="handleShowCloud(scope.row.name)" type="text">{{scope.row.name}}</el-button> -->
					<!-- <el-link :href="scope.row.name" type="primary"  target="_blank">{{scope.row.name}}</el-link> -->
				</template>
			</el-table-column>
		</el-table>
	</el-dialog>

	<el-dialog width="86%" :title="dialogDocTitle" @close="handleClearIframe" :visible.sync="dialogDocViewVisible">
		<iframe class="iframe_cs" :src="iframeSrc" id="show_doc"></iframe>
	</el-dialog>

	<el-dialog width="950px"  :before-close="handleCloseUpload" :close-on-click-modal="false" title="创建资源" :visible.sync="dialogUploadVisible"  :append-to-body="true">
		<el-row>
			<el-col :span="13">
				<el-row>
					<div style="margin:0 0 5px 0">资源名称：</div>
					<el-input type="text" :disabled="handleInputDisabled" placeholder="请输入内容" maxlength='200' :show-word-limit="true" v-model="input_case_name"></el-input>
				</el-row>
				<el-row style="margin:30px 0 0 0">
					<el-col :span="8">
						<div style="margin:0 0 5px 0">大类：</div>
						<el-select v-model="select_class" placeholder="请选择" @change="handleGetSecondClass">
							<el-option
							v-for="item in classlist"
							:key="item.id"
							:label="item.name"
							:value="item.id">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="8" v-if="showSecondClass">
						<div style="margin:0 0 5px 0">二类：</div>
						<el-select v-model="select_second_class" placeholder="请选择" @change="handleGetThirdClass">
							<el-option
							v-for="item in secondclasslist"
							:key="item.id"
							:label="item.name"
							:value="item.id">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="8" v-if="showThirdClass">
						<div style="margin:0 0 5px 0">三类：</div>
						<el-select v-model="select_third_class" placeholder="请选择" @change="handleSetThirdClass">
							<el-option
							v-for="item in thirdclasslist"
							:key="item.id"
							:label="item.name"
							:value="item.id">
							</el-option>
						</el-select>
					</el-col>
				</el-row>
				<el-row style="margin:30px 0 0 0">
					<el-col :span="8" >
						<div style="margin:0 0 5px 0">年龄分级：</div>
						<el-select v-model="select_age_type" placeholder="请选择" @change="handleSetAgeType">
							<el-option
							v-for="item in ageTypeList"
							:key="item.id"
							:label="item.name"
							:value="item.id">
							</el-option>
						</el-select>
					</el-col>
				</el-row>
				<el-row style="margin:30px 0 0 0">
					<el-col :span="8" >
						<div style="margin:0 0 5px 0">归属幼儿园：</div>
						<el-select v-model="select_kindergarten_uuid" placeholder="请选择" @change="handleSetKindergartenUUID">
							<el-option
							v-for="item in userUUIDlist"
							:key="item.uuid	"
							:label="item.name"
							:value="item.uuid">
							</el-option>
						</el-select>
					</el-col>
				</el-row>
				<el-row>
					<div style="margin:30px 0 5px 0">资源简介：</div>
					<el-input type="textarea" :disabled="handleInputDisabled" placeholder="请输入内容" rows="6" maxlength='2000' :show-word-limit="true" v-model="input_case_body"></el-input>
				</el-row>
			</el-col>
			<el-col :span="10" :offset="1" class="border_upload_panel">
				
				<el-upload
				class="upload-demo"
				ref="upload"
				action=""
				:on-preview="handlePreview"
				:on-remove="handleRemove"
				:on-change="handleChange"
				:http-request="handleClouldUpload"
				:file-list="fileList"
				:auto-upload="false" :multiple="true">
				<div class="upload_row_button"><el-button slot="trigger" type="success" plain>选取文件</el-button></div>
				<!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
				<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
				</el-upload>
				<div v-if="fileList.length == 0" class="border_upload_panel_tips1">可上传多个文件<br>请直接选择文件，不要打压缩包</div>
				<div v-if="fileList.length == 0" class="border_upload_panel_tips2">支持文件格式：<br>视频（MP4/MOV）、<br>文档（Word/PDF/TxT/PPT/Excel）、<br>音频（mp3）、图片（Jpg/Png/Gif）</div>
			

			</el-col>
		</el-row>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="handleRunSaveUpload" :loading="onUploadLoading">保存并上传</el-button>
		</span>
	</el-dialog>

	<div v-if="show_upload_status_panel" class="cover_upload_status_panel"></div>
	<div v-if="show_upload_status_panel" class="upload_status_panel">
		<div class="upload_status_panel_t">正在上传，不要关闭浏览器。</div>
		<div class="upload_status_panel_i">当前处理：{{upload_status_name}}</div>
		<div class="upload_status_panel_i">上传进度：{{upload_status_per}}%</div>
	</div>

	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			show_more_search:false,
			select_search_type:'all',
			select_search_word:'1',
			userUUIDlist:[],
			ageTypeList:[
				{id:0,name:'请选择'},
				{id:1,name:'3-4岁'},
				{id:2,name:'4-5岁'},
				{id:3,name:'5-6岁'},
				{id:4,name:'3-6岁'}
			],
			select_kindergarten_uuid:'',
			select_age_type:0,
			set_case_price:0,
			uploadPicNum:0,
			setRowID:'',
			hasPicList:[],
			filePicList:[],
			picList:[],
			dialogImageUrl:'',
			set_onshow_mini:0,
			set_ontop_mini:0,
			dialogShowInMiniVisible:false,
			select_class:'',
			select_second_class:'',
			select_third_class:'',
			classlist:[],
			secondclasslist:[],
			thirdclasslist:[],
			showSecondClass:false,
			showThirdClass:false,
			login_user_power:'',
			show_upload_status_panel:false,
			upload_status_name:'',
			upload_status_per:'',
			onUploadLoading:false,
			handleInputDisabled:false,
			fileList:[],
			uploadNum:0,
			fileNameList:[],
			input_case_name:'',
			input_case_body:'',
			pagenow:1,
			totalnum:0,
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			tableData: [],
			user_type:'',
			isedit:false,
			dialogDocTitle:'',
			iframeSrc:'',
			dialogUploadVisible:false,
			dialogGradeVisible:false,
			dialogDocViewVisible:false,
			dialogTableVisible:false,
			activeName:'customer',
			userCount:0,
			detailData: [],
			detailList:[],
			specialistData:[],
			gradelistData:[],
			topicsLink:'',
			rowDetail:'',
			bucket_name:'kindergarten-1325744197',
			topics_nickname:'',
			username:'',
			usertitle:'',
			specialUserID:'',
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData(1);
		let me = this;
		MN.kindergartenLoginUser().then(res => {
			me.login_user_power = res.u_power;
			return
		})
	},

	mounted() {

	},

	methods: {
		handleSetTypeSearch(){
			this.loading=true;
			let me = this;
			let post={
				search_type:this.select_search_type,
			}
			MN.searchTypeCaseData(post).then(res => {
				me.totalnum = res.total;
				me.tableData=res.data;
				me.loading=false;
			})	
		},
		handleResetType(){
			this.select_search_type = 'all';
			this.initData(1);
		},
		handleCloseSetSearch(){
			this.select_search_type = 'all';
			this.show_more_search = false;
			this.initData(1);
		},
		handleShowSetSearch(){
			this.show_more_search = true;
		},
		handleDelHasPic(picid){
			console.log( picid )
			let me = this;
			this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					pic_id:picid
				}
				MN.delCasePic(post).then(res => {
					me.$message({
						type: 'success',
						message: '删除成功!'
					});
					me.hasPicList.forEach((items,index) => {
						if ( items.id == picid){
							me.hasPicList.splice(index,1)
						}
					});
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
		},
		handlePicUploadSuccess(res, file) {
			let post={
				case_uuid:this.setRowID,
				pic_url:res.image_url
			}
			let me = this;
			MN.saveCasePicUrl(post).then(res => {
				me.uploadPicNum++;
				if ( me.picList.length == me.uploadPicNum ){
					me.$message.success('设置成功');
					let post2={
						case_uuid:this.setRowID,
						case_show:this.set_onshow_mini,
						case_top:this.set_ontop_mini,
						case_price:this.set_case_price
					}
					MN.saveCaseSet(post2).then(res => {
						me.dialogShowInMiniVisible = false;
						me.initData(1);
					})
				}
			})
		},
		handleSaveShowMini(){
			let me = this;
			if ( this.set_onshow_mini &&  this.picList.length == 0 && this.hasPicList.length == 0 ){
				this.$message.warning('在小程序展示必须要配图');
				return
			}

			if ( this.set_ontop_mini &&  this.picList.length == 0 && this.hasPicList.length == 0 ){
				this.$message.warning('在小程序展示必须要配图');
				return
			}

			if ( this.picList.length > 0 ){
				this.$refs.picupload.submit();
			}else{
				let post={
					case_uuid:this.setRowID,
					case_show:this.set_onshow_mini,
					case_top:this.set_ontop_mini,
					case_price:this.set_case_price
				}
				MN.saveCaseSet(post).then(res => {
					me.$message.success('设置成功');
					me.dialogShowInMiniVisible = false;
					me.initData(1);
				})
			}
			console.log(this.picList);
			
		},
		handlepictureChange(file, fileList){
			console.log( file );
			console.log( "length:" + fileList.length );

			let get_has_num = this.hasPicList.length;
			let total_num = parseInt(get_has_num) + parseInt(fileList.length);

			if ( total_num > 4 ){
				fileList.splice( (fileList.length - 1) ,1);
				this.$message.warning('上传数量限制');
				return
			}
			this.picList = fileList;
			return false
		},
		handleRemovePic(file) {
        	console.log(file.uid);
			this.picList.forEach((items,index) => {
				if ( items.uid == file.uid ){
					this.picList.splice(index,1)
				}
			});
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			// this.dialogVisible = true;
		},
		handleDownload(file) {
			console.log(file);
		},
		initData(p_num){
			let me = this;
			// this.user_type = sessionStorage.getItem('kindergarten_class_usertype');
			this.loading = true
			let post={
				p_num:p_num
			};
			MN.getCoursewareList(post).then(res => {
				me.totalnum = res.total;
				me.tableData=res.data;
				me.loading=false;
			})
		},
		handleDelCase(uuid){
			let me = this;
			this.$confirm("确认删除吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					uuid:uuid
				}
				MN.setDeleteCase(post).then(res => {
					if( res == 'set_ok' ){
						me.$message({
							type: 'success',
							message: '操作成功!'
						});
						me.initData(1);
					}
				})
				
			}).catch(() => {
				me.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},
		refreshList(){
			this.initData(1);
		},
		handleSearch(){

			this.loading=true;
			let me = this;
			let post={
				search_value:this.searchValue,
			}
			MN.searchCaseData(post).then(res => {
				me.totalnum = res.total;
				me.tableData=res.data;
				me.loading=false;
			})
			//this.initData('food');
		},
	 	randomString(len) {
            len = len || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';   
            var maxPos = $chars.length;
            var pwd = '';
            for (let i = 0; i < len; i++) {
                 pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },
		handleSetShow(case_uuid){
			let me = this;
			let post={
				case_uuid:case_uuid,
			}
			me.hasPicList = [];
			me.picList=[];
			MN.getCaseSetDetail(post).then(res => {
				me.set_ontop_mini  = res.ontop;
				me.set_onshow_mini = res.onshow;
				me.hasPicList = res.piclist;
				me.setRowID = case_uuid;
				
				me.dialogShowInMiniVisible = true;
				
			})
		},
		handlePreview(file) {
        	console.log(file);
      	},
		handleCloseUpload(done){
			this.input_case_name='';
			this.input_case_body='';
			this.fileList = [];
			this.$refs.upload.clearFiles();
			this.onUploadLoading=false;
			this.handleInputDisabled=false;
			return done(true)
		},
		handleRunSaveUpload(){
			if( !this.input_case_name ){
				this.$message.error('请输入资源名称');
				return
			}
			if( !this.input_case_body ){
				this.$message.error('请输入资源简介');
				return
			}

			if( !this.select_class ){
				this.$message.error('请选择大类');
				return
			}

			if( this.showSecondClass == true && !this.select_second_class ){
				this.$message.error('请选择二类');
				return
			}

			if( this.showThirdClass == true && !this.select_third_class ){
				this.$message.error('请选择三类');
				return
			}

			if( !this.select_age_type  || this.select_age_type == 0){
				this.$message.error('请选择年龄分类');
				return
			}


			if( !this.select_kindergarten_uuid  || this.select_kindergarten_uuid == 0){
				this.$message.error('请选择归属幼儿园');
				return
			}
			
			if( this.fileList.length == 0 ){
				this.$message.error('请选择要上传的文件');
				return
			}

			this.onUploadLoading=true;
			this.handleInputDisabled=true;
			this.$refs.upload.submit();
		},
		handlePicUpload(data,key){
			let me = this;
			
		},
		handleClouldUpload(data,key){
			console.log("data.file",data.file);
			console.log("data.file.name",data.file.name);
			console.log("data.file.type",data.file.type);
			// let formdata_ = new FormData();
			let me = this;
			let doctype = data.file.name.split(".");
			let get_case_name = data.file.name;
			let Supplementkey = get_case_name + '_' + Date.now();

			me.fileNameList.push({
				name:Supplementkey,
				type:doctype[doctype.length - 1]
			});

			me.show_upload_status_panel=true;

			let Bucket = 'kindergarten-1325744197';
			let Region = 'ap-beijing';
			var cos = new COS({
				getAuthorization: function (options, callback) {
					var url = 'https://www.xjjgweiguang.com/kindergarten/index/uploadQCcloud';
					var xhr = new XMLHttpRequest();
					xhr.open('GET', url, true);
					xhr.onload = function (e) {
						try {
							var data = JSON.parse(e.target.responseText);
							var credentials = data.credentials;
						} catch (e) {
						}
						if (!data || !credentials) {
						return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
						};
						callback({
						TmpSecretId: credentials.tmpSecretId,
						TmpSecretKey: credentials.tmpSecretKey,
						SecurityToken: credentials.sessionToken,
						StartTime: data.startTime,
						ExpiredTime: data.expiredTime,
					});
					};
					xhr.send();
				}
			});

			cos.uploadFile({
				Bucket: Bucket,
				Region: Region,
				Key: Supplementkey,/* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
				Body: data.file, // 上传文件对象
				SliceSize: 1024 * 1024 * 5,
				onProgress: function(progressData) {
					
					let get_percent =  progressData.percent.toString().split('.');
					me.upload_status_name=get_case_name;
					me.upload_status_per=get_percent[1];

					// var get_percent =  progressData.percent.toString().split('.')
					if ( get_percent[0] == '1' ){
						me.upload_status_per='100';
					}else{
						me.upload_status_per=get_percent[1];
					}
					
					console.log(JSON.stringify(progressData));
				}
			}, function(err, data) {
				if (err) {
					console.log('上传失败', err);
				} else {
					console.log('上传成功',data);
					me.uploadNum++;
					if ( me.fileList.length == me.uploadNum ){
						console.log('全部上传完毕');
						let post={
							case_name:me.input_case_name,
							case_body:me.input_case_body,
							first_class:me.select_class,
							second_class:me.select_second_class,
							third_class:me.select_third_class,
							age_type:me.select_age_type,
							kindergarten_uuid:me.select_kindergarten_uuid,
							fileList:JSON.stringify(me.fileNameList)
						}
						MN.setNewUploadDataSave(post).then(res => {
							me.uploadNum = 0;
							me.input_case_name='';
							me.input_case_body='';
							me.fileList = [];
							me.fileNameList = [];
							me.$refs.upload.clearFiles();
							me.onUploadLoading=false;
							me.handleInputDisabled=false;
							me.dialogUploadVisible=false;
							me.upload_status_name='';
							me.upload_status_per='';
							me.show_upload_status_panel=false;
							me.$message.success('新建资源成功');
							me.initData(1);
						});
						console.log("input_case_name",me.input_case_name);
						console.log("input_case_body",me.input_case_body);
						console.log("fileNameList",me.fileNameList);
					}
				}
			});
			
		},
		showUploadFileDialog(){
			let me = this;
			this.showSecondClass=false;
			this.showThirdClass=false;
			this.secondclasslist = [];
			this.thirdclasslist = [];
			this.select_class = '';
			this.select_second_class = '';
			this.select_third_class = '';
			this.dialogUploadVisible = true;
			console.log("!");
			let post={
				pid:0,
				ppid:0
			}
			MN.getCaseClassList(post).then(res => {
				if( res ){
					me.classlist = res.class_list
					me.userUUIDlist = res.user_klist
				}
			})
		},
		handleGetSecondClass(e){
			console.log(e);
			this.select_class = e;
			this.select_second_class = '';
			this.select_third_class = '';
			this.showThirdClass = false;
			let post={
				pid:e,
				ppid:0
			}
			MN.getCaseClassList(post).then(res => {
				if( res.class_list.length != 0 ){
					this.secondclasslist = res.class_list;
					this.showSecondClass = true;
				}else{
					this.showSecondClass = false;
				}
			})
		},
		handleGetThirdClass(e){
			this.select_second_class = e;
			this.select_third_class = '';
			this.showThirdClass = false;
			let post={
				pid:0,
				ppid:e
			}
			MN.getCaseClassList(post).then(res => {
				if( res.class_list.length != 0 ){
					this.thirdclasslist = res.class_list;
					this.showThirdClass = true;
				}else{
					this.showThirdClass = false;
				}
			})
		},
		handleSetKindergartenUUID(e){
			this.select_kindergarten_uuid = e;
		},
		handleSetAgeType(e){
			this.select_age_type = e;
		},
		handleSetThirdClass(e){
			this.select_third_class = e;
		},
		handleShowUploadFile(uuid){
			console.log(uuid)
			// let rowlist = rowdata.split(",");
			// let me = this;
			// me.rowDetail = [];
			// console.log(rowlist)
			// rowlist.forEach(items => {
			// 	if ( items ){
			// 		me.rowDetail.push({
			// 			name:items,
			// 			url:items
			// 		});
			// 	}
			// });
			let me = this;
			this.loading = true
			let post={
				case_uuid:uuid
			}
			MN.getCaseDetail(post).then(res => {
				me.dialogTableVisible = true;
				me.loading = false;
				me.rowDetail = res
			});

		},
		submitUpload() {
        	this.$refs.upload.submit();
		},
		handleChange(file, fileList) {
			console.log(file, fileList);
			this.fileList = fileList
			if( !this.input_case_name ){
				let get_temp_name = fileList[0].name.split(".");
				this.input_case_name = get_temp_name[0];
			}
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.fileList = fileList
		},
		handleClearIframe(){
			this.iframeSrc='';
			this.dialogDocTitle ='';
		},
		handleShowDetail(order_uuid){
			this.$router.push('/order/detail?order_uuid='+order_uuid)
		},
		handleCurrentChange(new_page) {
			this.initData(new_page);
      	},
		handleShowCloud(filedata,showtype){
			console.log("filedata",filedata);
			let me = this;
			me.dialogDocTitle=filedata;
			me.iframeSrc = '';
			var Bucket ='kindergarten-1325744197';
			var Region = 'ap-beijing';
			var authorization_url = 'https://www.xjjgweiguang.com/kindergarten/index/uploadQCcloud';

			var cos = new COS({
				getAuthorization: function (options, callback) {
					var url = authorization_url;
					var xhr = new XMLHttpRequest();
					xhr.open('GET', url, true);
					xhr.onload = function (e) {
						try {
							var data = JSON.parse(e.target.responseText);
							var credentials = data.credentials;
						} catch (e) {
						}
						if (!data || !credentials) {
						return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
						};
						callback({
						TmpSecretId: credentials.tmpSecretId,
						TmpSecretKey: credentials.tmpSecretKey,
						SecurityToken: credentials.sessionToken,
						StartTime: data.startTime,
						ExpiredTime: data.expiredTime,
					});
					};
					xhr.send();
				}
			});

			if ( showtype == 2 ){ //直接下载
				cos.getObjectUrl({
					Domain:'cloud.xjjgweiguang.com',
        			Protocol:'https:',
					Bucket:'kindergarten-1325744197', /* 填入您自己的存储桶，必须字段 */
					Region: 'ap-beijing', /* 存储桶所在地域，例如 ap-beijing，必须字段 */
					Key: filedata, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段 */
					Sign: true, /* 获取带签名的对象 URL */
				},
				function (err, data) {
					if (err) return console.log(err);
					/* url为对象访问 url */
					var url = data.Url;
					console.log(url)

					// me.iframeSrc = url;
					// me.dialogDocViewVisible = true;
					//location.href=url
					//window.open(url)
					// $(".iframe_body").show()
					// $(".cover_all").show()
					// $("#show_doc").attr('src',url)
					/* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
					var downloadUrl =
					url +
					(url.indexOf('?') > -1 ? '&' : '?') +
					'response-content-disposition=attachment'; // 补充强制下载的参数
					console.log(downloadUrl)

					me.iframeSrc = downloadUrl;
					window.open(downloadUrl)
					// me.dialogDocViewVisible = true;
				});
				return
			}

			let check_fileid = filedata.split(".");
			if ( check_fileid[check_fileid.length - 1] == "jpg" || check_fileid[check_fileid.length - 1] == "jpeg" || check_fileid[check_fileid.length - 1] == "png" || check_fileid[check_fileid.length - 1] == "mp4" || check_fileid[check_fileid.length - 1] == "gif" || check_fileid[check_fileid.length - 1] == "mov"  || check_fileid[check_fileid.length - 1] == ""){
				cos.getObjectUrl({
					Domain:'cloud.xjjgweiguang.com',
        			Protocol:'https:',
					Bucket:'kindergarten-1325744197', /* 填入您自己的存储桶，必须字段 */
					Region: 'ap-beijing', /* 存储桶所在地域，例如 ap-beijing，必须字段 */
					Key: filedata, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段 */
					Sign: true, /* 获取带签名的对象 URL */
				},
				function (err, data) {
					if (err) return console.log(err);
					/* url为对象访问 url */
					var url = data.Url;
					console.log(url)

					//url = url + (url.indexOf('?') > -1 ? '&' : '?') +'response-content-disposition=inline';

					me.iframeSrc = url;
					me.dialogDocViewVisible = true;
					//location.href=url
					//window.open(url)
					// $(".iframe_body").show()
					// $(".cover_all").show()
					// $("#show_doc").attr('src',url)
					/* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
					 // 补充强制下载的参数
					// console.log(downloadUrl)
				});
			}else if (  check_fileid[check_fileid.length - 1] == "txt" || check_fileid[check_fileid.length - 1] == "pptx" ||  check_fileid[check_fileid.length - 1] == "ppt" ||  check_fileid[check_fileid.length - 1] == "doc" || check_fileid[check_fileid.length - 1] == "docx" || check_fileid[check_fileid.length - 1] == "pdf" || check_fileid[check_fileid.length - 1] == "xls" || check_fileid[check_fileid.length - 1] == "xlsx" ){
				cos.getObjectUrl({
					Domain:'cloud.xjjgweiguang.com',
        			Protocol:'https:',
					Bucket: 'kindergarten-1325744197',
					Region: 'ap-beijing',
					Key: filedata,
					Query: {
					'ci-process': 'doc-preview', /* 必须，数据万象处理能力，文档预览固定为 doc-preview */
					dstType: 'html',
					},
				}, function(err, data) {
					if (err) {
					console.log(err);
					} else {
					// 使用浏览器打开 url 即可预览
					var url = data.Url;
					//url = url + (url.indexOf('?') > -1 ? '&' : '?') +'response-content-disposition=inline';
					me.iframeSrc = url;
					me.dialogDocViewVisible = true;
					// $(".iframe_body").show()
					// $(".cover_all").show()
					// $("#show_doc").attr('src',url)
					// window.open(url)
					// console.log(url);
					}
				});
			}else{
				cos.getObjectUrl({
					Domain:'cloud.xjjgweiguang.com',
        			Protocol:'https:',
					Bucket:'kindergarten-1325744197', /* 填入您自己的存储桶，必须字段 */
					Region: 'ap-beijing', /* 存储桶所在地域，例如 ap-beijing，必须字段 */
					Key: filedata, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段 */
					Sign: true, /* 获取带签名的对象 URL */
				},
				function (err, data) {
					if (err) return console.log(err);
					/* url为对象访问 url */
					var url = data.Url;
					console.log(url)
					//url = url + (url.indexOf('?') > -1 ? '&' : '?') +'response-content-disposition=inline';
					me.iframeSrc = url;
					me.dialogDocViewVisible = true;
					//location.href=url
					//window.open(url)
					// $(".iframe_body").show()
					// $(".cover_all").show()
					// $("#show_doc").attr('src',url)
					/* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
					// var downloadUrl =
					// url +
					// (url.indexOf('?') > -1 ? '&' : '?') +
					// 'response-content-disposition=attachment'; // 补充强制下载的参数
					// console.log(downloadUrl)
				});
			}

			

		},
	}
}
</script>

<style scoped>
.close_search_tool{ padding: 5px; font-size: 15px; cursor: pointer; position: absolute; right: 10px; top: 10px; }
.sux_io_row{ margin: 25px 0 15px 0; }
.button_big_reset{}
.button_big_search{ }
.more_search_bar{ background-color: #fafeff; margin: 10px 0 10px 0; position: relative; padding:15px; border-radius: 8px; box-shadow: 2px 1px 4px #c7c7c7; }
.page_bar_tool{ text-align: right; }
.input-with-select{ width: 200px; margin-left: 10px; margin-right: 5px; }
.xdif_row{ padding: 15px 0 15px 0; border-bottom: 1px dashed #d5d5d5; }
.row_label{ color: #396a97; font-size: 16px; }
.sxia_tips{ background: #f5fbff; padding: 10px; border-radius: 10px; margin: 15px 0 0 0; }
.pic_list_li{ position: relative; width:148px; height: 148px; border: 1px dashed #c0ccda; border-radius: 6px; float: left; margin-right: 10px;}
.btn_del_picimg{ cursor: pointer; position: absolute;  width: 40px; height: 40px; padding: 0; left: 0; right: 0; margin: 0 auto; top: 50px;  }
.pic_images{ width:148px; height: 148px; }
.has_pic_list{float: left;  }
.sixc_row{ margin: 15px 0 0 0; }

.icon_file_type{ width: 30px; }
.icon_file_row_type{ width:45px; }
.scope_case_title{ font-weight: bold; }
.scope_case_showid{ font-size: 12px; color: #a5a5a5; margin-top: 4px; }
#show_doc{
	width: 100%;
	height: 650px;
	border: none;
}
.clear{ clear: both; }
.border_upload_panel_tips1{ text-align: left; margin: 20px 0 10px 0; font-size: 14px; color: #000000; line-height: 19px; }
.border_upload_panel_tips2{ text-align: left; margin: 0 0 30px 0; font-size: 13px; color: #1e8919;  line-height: 19px;}
.border_upload_panel{ border: 1px dashed #3d8471; background-color: #fbfff9; box-shadow: 2px 3px 4px #d7ffd5;  border-radius: 4px; min-height: 330px; padding: 15px; }
.upload_row_button{ text-align:left; margin: 10px 0 0 0; }
.cover_upload_status_panel{ color: #FFFFFF; position: fixed; z-index: 4000; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.7; }
.upload_status_panel{  padding: 15px; border-radius: 10px; background-color: #FFFFFF; width:500px; margin: 0 auto;  position: fixed; z-index: 4200; left: 0; top: 20%; right: 0;  height: 200px;  }
.upload_status_panel_i{ margin-bottom: 15px; font-size: 16px;}
.upload_status_panel_t{ text-align: center; color: #e84141; font-size: 22px; margin: 20px 0 10px 0; font-weight: bolder; }
</style>
